type ParsedTagDetails = {
  tag: string;
  value: string;
  attributes: string;
  parsedAttributes: Record<string, string>;
};

const parseAttributes = (attributeString: string) => {
  const attributesObject: Record<string, string> = {};
  const regex = /(\w+)=["']?((?:.(?!["']?\s+(?:\S+)=|[>"']))+.)["']?/g;
  let match;

  while ((match = regex.exec(attributeString)) !== null) {
    const key = match[1];
    let value = match[2];

    value = value.replaceAll(/^['"]|['"]$/g, "");
    attributesObject[key] = value;
  }

  return attributesObject;
};

export const htmlParser = (html: string): ParsedTagDetails[] => {
  const result = [];
  const tagRegex = /<([^\/>\s]+)([^>]*)>(.*?)<\/\1>/gs;
  let match;

  while ((match = tagRegex.exec(html)) !== null) {
    const tag = match[1];
    const attributes = match[2];
    const parsedAttributes = parseAttributes(attributes);

    const content = match[3];

    result.push({ tag, value: content, attributes, parsedAttributes });
  }

  return result;
};
